import {
  Border,
  Dimension,
  Padding,
  PerCornerBorderRadius,
  PerSideBorder,
  ThemeMode,
  Typographies,
  UserDefinedPalette,
  UserDefinedTheme,
} from "@superblocksteam/shared";
import {
  BASE_THEME_EXISTING_APPS_PADDING,
  PADDING_PRESETS_BY_THEME_VERSION,
} from "legacy/themes/paddingConstants";
import { colors } from "styles/colors";
import {
  DEFAULT_TYPOGRAPHIES_LEGACY,
  DEFAULT_TYPOGRAPHIES_NEW,
} from "./typographyConstants";

export const CURRENT_THEME_VERSION = 5;

export const LIGHT_APP_BG = colors.GREY_25;
export const DARK_APP_BG = "#131516";

const DEFAULT_DARK_PALETTE = {
  primaryColor: colors.ACCENT_BLUE_500,
  appBackgroundColor: DARK_APP_BG,
};

const DEFAULT_LIGHT_PALETTE = {
  primaryColor: colors.ACCENT_BLUE_500,
  appBackgroundColor: LIGHT_APP_BG,
};

const NO_BORDER: Border = {
  width: Dimension.px(0),
  style: "solid",
  color: "{{ theme.colors.neutral100 }}",
};

const DEFAULT_CONTAINER_BORDER: Border = {
  width: Dimension.px(1),
  style: "solid",
  color: "{{ theme.colors.neutral100 }}",
};

export const NO_BORDER_OBJECT: PerSideBorder = {
  left: NO_BORDER,
  right: NO_BORDER,
  top: NO_BORDER,
  bottom: NO_BORDER,
};

export const EMPTY_RADIUS: PerCornerBorderRadius = {
  topLeft: Dimension.px(0),
  topRight: Dimension.px(0),
  bottomLeft: Dimension.px(0),
  bottomRight: Dimension.px(0),
};

export const DEFAULT_SYSTEM_RADIUS: PerCornerBorderRadius = {
  topLeft: Dimension.px(4),
  topRight: Dimension.px(4),
  bottomLeft: Dimension.px(4),
  bottomRight: Dimension.px(4),
};

export const DEFAULT_CONTAINER_BORDER_OBJECT: PerSideBorder = {
  left: DEFAULT_CONTAINER_BORDER,
  right: DEFAULT_CONTAINER_BORDER,
  top: DEFAULT_CONTAINER_BORDER,
  bottom: DEFAULT_CONTAINER_BORDER,
};

export type UserDefinedThemeWithPalette = UserDefinedTheme & {
  palette: {
    dark: UserDefinedPalette;
    light: UserDefinedPalette;
  };
};

type UserDefinedThemeWithTypography = UserDefinedThemeWithPalette & {
  typographies: Typographies; // make required
};

export type UserDefinedThemeWithVersion = (
  | UserDefinedTheme
  | UserDefinedThemeWithPalette
  | UserDefinedThemeWithTypography
) & {
  version: number;
};

export const BASE_THEME_EXISTING_APPS: UserDefinedThemeWithPalette = {
  typeFace: "Roboto",
  borderRadius: { value: 4, mode: "px" },
  mode: ThemeMode.LIGHT,
  primaryColor: colors.ACCENT_BLUE_500,
  padding: BASE_THEME_EXISTING_APPS_PADDING,
  palette: {
    dark: DEFAULT_DARK_PALETTE,
    light: DEFAULT_LIGHT_PALETTE,
  },
  version: 1,
};

// BASE_THEME_EXISTING_APPS represents the styles used for apps before theming was released
const BASE_THEME_V1 = BASE_THEME_EXISTING_APPS;

const BASE_THEME_V2: UserDefinedThemeWithPalette = {
  typeFace: "Roboto",
  borderRadius: { value: 4, mode: "px" },
  mode: ThemeMode.AUTO,
  primaryColor: colors.ACCENT_BLUE_500,
  padding: PADDING_PRESETS_BY_THEME_VERSION[2].medium.value,
  palette: {
    dark: DEFAULT_DARK_PALETTE,
    light: DEFAULT_LIGHT_PALETTE,
  },
  version: 2,
};

const BASE_THEME_V3: UserDefinedThemeWithPalette = {
  // Same as v2 except padding
  typeFace: "Roboto",
  borderRadius: { value: 4, mode: "px" },
  mode: ThemeMode.AUTO,
  primaryColor: colors.ACCENT_BLUE_500,
  palette: {
    dark: DEFAULT_DARK_PALETTE,
    light: DEFAULT_LIGHT_PALETTE,
  },
  padding: PADDING_PRESETS_BY_THEME_VERSION[3].medium.value,
  version: 3,
};

// with typography but using legacy typographies
const BASE_THEME_V4: UserDefinedThemeWithTypography = {
  ...BASE_THEME_V3,
  typographies: DEFAULT_TYPOGRAPHIES_LEGACY,
  version: 4,
};

// update typogrpahies to match new design system
const BASE_THEME_V5: UserDefinedThemeWithTypography = {
  ...BASE_THEME_V4,
  typographies: DEFAULT_TYPOGRAPHIES_NEW,
  version: 5,
};

export const BASE_THEMES_BY_VERSION: Record<
  number,
  UserDefinedThemeWithPalette
> = {
  1: BASE_THEME_V1,
  2: BASE_THEME_V2,
  3: BASE_THEME_V3,
  4: BASE_THEME_V4,
  5: BASE_THEME_V5,
};

export const BASE_THEME_NEW_APPS =
  BASE_THEMES_BY_VERSION[CURRENT_THEME_VERSION];

export const CODE_EDITOR_LIGHT_COLORS = {
  text: "#2e383c",
  comment: "#c0c0c0",
  property: "#7a7a7a",
  number: "#ccabd4",
  tag: "#9c3328",
  string: "#18a0fb",
  variable: "#929adc",
  keyword: "#91c9e4",
  builtin: "#e5ab64",
};

export const CODE_EDITOR_DARK_COLORS = {
  text: "#eaeaea",
  comment: "#7a7a7a",
  property: "#b3b3b3",
  number: "#f3b7f9", // Brighter version of the previous color for contrast
  tag: "#f76e61", // Brightened for visibility against dark background
  string: "#4fb7ff", // A more luminous blue for better visibility
  variable: "#b8b8ff", // Brighter version for visibility
  keyword: "#b1e4ff", // A more luminous shade to stand out on dark
  builtin: "#ffb68a", // Brightened for better contrast
};

export const LIGHT_MODE_NEUTRALS = [
  "#FFFFFF", // neutral
  "#F9FAFB", // 25
  "#F3F4F6", // 50
  "#E8EAED", // 100
  "#C6CAD2", // 200
  "#A4AAB7", // 300
  "#818A9C", // 400
  "#6C7689", // 500
  "#454D5F", // 700
  "#1F2633", // 900
];

export const DARK_MODE_NEUTRALS = [
  "#1C1F21", // neutral
  "#1E2224", // 25
  "#262A2C", // 50
  "#2F3437", // 100
  "#393F42", // 200
  "#545E63", // 300
  "#677379", // 400
  "#859197", // 500
  "#DEE1E3", // 700
  "#FFFFFF", // 900
];

export const BOX_SHADOWS = {
  popover: {
    light:
      "0px 0px 1px 0px rgba(34, 39, 47, 0.32), 0px 12px 32px -8px rgba(34, 39, 47, 0.16), 0px 1px 3px 0px rgba(34, 39, 47, 0.12)",
    dark: "0px 0px 1px 0px rgba(200, 200, 200, 0.32), 0px 12px 32px -8px rgba(200, 200, 200, 0.16), 0px 1px 3px 0px rgba(200, 200, 200, 0.12)",
  },
  modal: {
    light:
      "0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05)",
    dark: "0 3px 6px -4px rgba(160, 160, 160, 0.03), 0 6px 16px 0 rgba(160, 160, 160, 0.01)",
  },
};

export const BUTTON_PADDING: Padding = {
  left: { mode: "px", value: 10 },
  right: { mode: "px", value: 10 },
  top: { mode: "px", value: 9 },
  bottom: { mode: "px", value: 9 },
};

export const DROPDOWN_PADDING = {
  left: Dimension.px(10),
  right: Dimension.px(10),
  top: Dimension.px(6),
  bottom: Dimension.px(6),
};

export const INPUT_PADDING = {
  left: Dimension.px(10),
  right: Dimension.px(10),
  top: Dimension.px(6),
  bottom: Dimension.px(6),
};
