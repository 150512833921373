import { Span, trace, context, Tracer, propagation } from "@opentelemetry/api";
import {
  OBS_TAG_ORG_ID,
  OBS_TAG_ORG_NAME,
  OBS_TAG_RESOURCE_ID,
  OBS_TAG_RESOURCE_TYPE,
  OBS_TAG_USER_EMAIL,
  UIErrorType,
  OBS_TAG_ERROR_PRIORITY,
  OBS_TAG_ERROR_TYPE,
} from "@superblocksteam/shared";

const TRACER_ID = "superblocks";

const MAX_SESSION_LENGTH = 60 * 60 * 1000; // 1 hour

type GlobalAttribute = {
  [OBS_TAG_RESOURCE_ID]?: string;
  [OBS_TAG_RESOURCE_TYPE]?: string;
  [OBS_TAG_ORG_ID]?: string;
  [OBS_TAG_ORG_NAME]?: string;
  [OBS_TAG_USER_EMAIL]?: string;
};

class UITracing {
  static sessionSpan?: Span;
  static tracer: Tracer = trace.getTracer(TRACER_ID);
  static sessionTimeout?: ReturnType<typeof setTimeout>;
  static globalAttributes?: GlobalAttribute = {};
  private static spansById = new Map<string, Span>();

  static getTracer() {
    return UITracing.tracer;
  }

  static startSession(appId: string) {
    UITracing.endSession();
    UITracing.sessionSpan = UITracing.tracer.startSpan("session", {
      attributes: {
        ...UITracing.getGlobalAttributes(),
        [OBS_TAG_RESOURCE_ID]: appId,
      },
    });
    UITracing.spansById.set(
      UITracing.sessionSpan.spanContext().spanId,
      UITracing.sessionSpan,
    );

    // If session is not ended after 60 minutes, end + start a new one
    UITracing.sessionTimeout && clearTimeout(UITracing.sessionTimeout);
    UITracing.sessionTimeout = setTimeout(() => {
      UITracing.endSession();
      UITracing.startSession(appId);
    }, MAX_SESSION_LENGTH);

    return UITracing.sessionSpan;
  }

  static endSession() {
    if (UITracing.sessionSpan) {
      UITracing.spansById.delete(UITracing.sessionSpan.spanContext().spanId);
      UITracing.sessionSpan.end();
      UITracing.sessionSpan = undefined;
    }
  }

  static getContext(parentSpan?: Span) {
    const active = trace.getActiveSpan();
    if (!active) {
      if (parentSpan) {
        const ctx = trace.setSpan(context.active(), parentSpan);
        return ctx;
      } else if (UITracing.sessionSpan) {
        const ctx = trace.setSpan(context.active(), UITracing.sessionSpan);
        return ctx;
      }
    }
    return context.active();
  }

  static getGlobalAttributes() {
    return UITracing.globalAttributes;
  }

  static setGlobalAttributes(attributes?: GlobalAttribute) {
    UITracing.globalAttributes = attributes;
  }

  static startSpan(
    spanName: string,
    parentSpanId?: string,
    additionalAttributes?: Record<string, undefined | string>,
  ) {
    const tracer = UITracing.getTracer();

    const ctx = UITracing.getContext(
      UITracing.spansById.get(parentSpanId ?? ""),
    );
    const span =
      tracer &&
      tracer.startSpan(
        spanName,
        {
          attributes: {
            ...UITracing.getGlobalAttributes(),
            ...additionalAttributes,
          },
        },
        ctx,
      );

    const spanId = span.spanContext().spanId;
    this.spansById.set(spanId, span);

    return spanId;
  }

  static endSpan(spanId?: string) {
    const span = UITracing.spansById.get(spanId ?? "");
    if (spanId && span) {
      span.end();
      UITracing.spansById.delete(spanId);
    }
  }

  static addEvent(
    spanId: undefined | string,
    message: string,
    errorType?: UIErrorType,
  ) {
    const span = this.spansById.get(spanId ?? "");
    if (!span) {
      return;
    }
    span.addEvent(message, {
      ...UITracing.getGlobalAttributes(),
      [OBS_TAG_ERROR_TYPE]: errorType,
      [OBS_TAG_ERROR_PRIORITY]:
        errorType === UIErrorType.CRASH_APP_ERROR ||
        errorType === UIErrorType.EXECUTION_ERROR
          ? 1
          : errorType != null
            ? 2
            : undefined,
    });
  }

  static getTracingHeaders(spanId: string | undefined) {
    const span = UITracing.spansById.get(spanId ?? "");
    if (!span) {
      return {};
    }
    const headers = {};
    propagation.inject(UITracing.getContext(span), headers);
    return headers;
  }
}
export default UITracing;
