import { memoize } from "proxy-memoize";
import { createSelector } from "reselect";
import { DataTreeWidget } from "legacy/entities/DataTree/dataTreeFactory";
import {
  MetaState,
  WidgetMetadata,
} from "legacy/reducers/entityReducers/metaReducer";
import { getDataTreeWidgetsById } from "./dataTreeSelectors";
import { getWidgetsMeta } from "./sagaSelectors";
import type { DynamicVisibilityProperties } from "legacy/widgets/BaseWidget";

export type DynamicWidgetsVisibilityState = {
  [widgetId: string]: DynamicVisibilityProperties;
};

// This function is memoized to prevent re-computation when irrelevant properties in metaState change
// it should merge evalState and metaState
const merge = memoize(
  ({
    evalState,
    metaState,
  }: {
    evalState: Record<string, DataTreeWidget>;
    metaState: MetaState;
  }) => {
    const result: DynamicWidgetsVisibilityState = {};
    for (const widgetId in evalState) {
      const widget = evalState[widgetId];
      result[widgetId] = {
        isVisible: widget.isVisible ?? true,
        collapseWhenHidden: widget.collapseWhenHidden ?? false,
      };
    }
    for (const widgetId in metaState) {
      const widgetMeta = metaState[widgetId] as WidgetMetadata &
        DynamicVisibilityProperties;
      result[widgetId] = {
        isVisible: widgetMeta.isVisible ?? result[widgetId]?.isVisible ?? true,
        collapseWhenHidden:
          widgetMeta.collapseWhenHidden ??
          result[widgetId]?.collapseWhenHidden ??
          false,
      };
    }
    return result;
  },
);

export const getDynamicVisibilityWidgets = createSelector(
  getDataTreeWidgetsById,
  getWidgetsMeta,
  (evalState, metaState) => merge({ evalState, metaState }),
);
