import {
  AgentType,
  AgentsHealthStatus,
  Profile,
} from "@superblocksteam/shared";
import { Typography } from "antd";
import React from "react";
import { useInRouterContext } from "react-router";
import {
  AGENTS_DEPLOYMENT_INSTRUCTIONS_URL,
  DOCS_URL,
  OPA_URL,
  SUPPORT_EMAIL_ADDRESS,
} from "legacy/constants/routes";
import unreachable from "utils/unreachable";
import Link from "../../components/ui/Link";

/**
 * A implementation of <Link> that uses the react-router Link component or an anchor tag, based on
 * whether the component is rendered in a router context or not. This is necessary because sometimes
 * the error messages are rendered in alerts, which are rendered outside of the router context.
 */
const InternalLink: React.FC<React.ComponentProps<typeof Link>> = (props) => {
  const inRouterContext = useInRouterContext();
  return inRouterContext ? (
    <Link {...props} />
  ) : (
    <Typography.Link href={props.to}>{props.children}</Typography.Link>
  );
};

interface AgentErrorProps {
  organizationAgentType: AgentType;
  userIsAdmin: boolean;
  agentsHealthStatus: AgentsHealthStatus;
  profile: Profile | undefined;
}

/**
 * Displays a detailed error message about the health status of the agents. The message is contextual and includes hints
 * on how to resolve the issue.
 */
export const NoActiveAgentDetailedMessage: React.FC<AgentErrorProps> = (
  props,
) => {
  if (props.organizationAgentType !== AgentType.ONPREMISE) {
    return (
      <>
        Cannot connect to Superblocks Cloud agents. Please check your network
        and contact the Superblocks team at{" "}
        <Typography.Link href={`mailto:${SUPPORT_EMAIL_ADDRESS}`}>
          {SUPPORT_EMAIL_ADDRESS}
        </Typography.Link>
      </>
    );
  }
  if (props.agentsHealthStatus === AgentsHealthStatus.HEALTHY) {
    // This should not happen
    return <>This agent is healthy.</>;
  } else if (props.agentsHealthStatus === AgentsHealthStatus.UNKNOWN) {
    // This should not happen
    return <>This agent is in an unknown state.</>;
  }
  if (props.userIsAdmin) {
    switch (props.agentsHealthStatus) {
      case AgentsHealthStatus.NO_AGENTS:
        return (
          <>
            Unable to connect to on-premise agent(s). No agents are registered.{" "}
            <InternalLink to={AGENTS_DEPLOYMENT_INSTRUCTIONS_URL}>
              Deploy an agent
            </InternalLink>{" "}
            or{" "}
            <InternalLink to={OPA_URL}>
              update your org to use Superblocks Cloud
            </InternalLink>
          </>
        );
      case AgentsHealthStatus.NO_AGENTS_FOR_PROFILE:
        return (
          <>
            Unable to connect to on-premise agent(s). No agents are registered
            for{" "}
            {props.profile?.displayName ??
              props.profile?.key ??
              " the current profile"}
            .{" "}
            <Link to={AGENTS_DEPLOYMENT_INSTRUCTIONS_URL}>Deploy an agent</Link>{" "}
            with the tag{" "}
            <code>profile:{props.profile?.key ?? "<PROFILE_KEY>"}</code>
          </>
        );
      case AgentsHealthStatus.NO_REACHABLE_AGENTS:
        return (
          <>
            Unable to connect to on-premise agent(s). Check your VPN and
            firewall rules of your agent. Refer to the{" "}
            <Typography.Link
              href={`${DOCS_URL}/on-premise-agent/manage/troubleshooting`}
            >
              troubleshooting guide
            </Typography.Link>{" "}
            for more info
          </>
        );
      default:
        unreachable(props.agentsHealthStatus);
    }
  } else {
    switch (props.agentsHealthStatus) {
      case AgentsHealthStatus.NO_AGENTS:
        return (
          <>
            Unable to connect to on-premise agent(s). No agents are registered.
            Please contact your admins for assistance
          </>
        );
      case AgentsHealthStatus.NO_AGENTS_FOR_PROFILE:
        return (
          <>
            Unable to connect to on-premise agent(s). No agents are registered
            for{" "}
            {props.profile?.displayName ??
              props.profile?.key ??
              " the current profile"}
            . Please contact your admins for assistance
          </>
        );
      case AgentsHealthStatus.NO_REACHABLE_AGENTS:
        return (
          <>
            Unable to connect to on-premise agent(s). Please log in to your VPN
            and check network connection. Contact your admins if the issue
            persists
          </>
        );
      default:
        unreachable(props.agentsHealthStatus);
    }
  }
};
