import { createCachedSelector } from "re-reselect";
import { createSelector } from "reselect";
import type { DynamicLayoutState } from "legacy/reducers/evaluationReducers/dynamicLayoutReducer";
import type { AppState } from "store/types";

const getDynamicLayoutState = (state: AppState): DynamicLayoutState =>
  state.legacy.evaluations.dynamicLayout;

export const getDynamicLayoutWidgets = createSelector(
  getDynamicLayoutState,
  (dynamicLayout) => dynamicLayout.widgets,
);

export const getDynamicLayoutWidget = createCachedSelector(
  getDynamicLayoutWidgets,
  (_: unknown, widgetId: string) => widgetId,
  (widgets, widgetId) => widgets[widgetId],
)((_: unknown, widgetId: string) => widgetId);
