import { LoadingOutlined } from "@ant-design/icons";
import { Spin, SpinProps } from "antd";
import React from "react";
import styled from "styled-components";

const LoadingIcon = styled(LoadingOutlined)<{ color?: string }>`
  color: ${(props) => props.color ?? props.theme.colors.ACCENT_BLUE_500};
`;

export const Spinner = (
  props: SpinProps & {
    color?: string;
    children?: React.ReactNode;
  },
) => {
  return (
    <Spin indicator={<LoadingIcon color={props.color} />} {...props}>
      {props.children}
    </Spin>
  );
};
