import { Dimension } from "@superblocksteam/shared";
import React from "react";
import {
  WidgetTypes,
  type WidgetType,
  GridDefaults,
} from "legacy/constants/WidgetConstants";
import WIDGET_DOC_URLS from "legacy/constants/WidgetDocUrls";
import {
  WidgetPropertyValidationType,
  BASE_WIDGET_VALIDATION,
} from "legacy/constants/WidgetValidation";
import {
  registerPropertyPaneConfig,
  registerV2PropertyPaneConfig,
} from "legacy/pages/Editor/PropertyPane/ItemPropertyPaneConfig";
import { APP_MODE } from "legacy/reducers/types";
import { getWidgetAHFromProps } from "legacy/sagas/autoHeight/utils";
import { GeneratedTheme } from "legacy/themes";
import { isFixedHeight } from "legacy/widgets/base/sizing/dynamicLayoutUtils";
import { Flags } from "store/slices/featureFlags";
import { isCustomComponentType } from "./CustomComponentTypeUtil";
import { getApplicableMaxHeight, getApplicableMinHeight } from "./base/sizing";
import type {
  default as BaseWidget,
  WidgetProps,
  WidgetPropsRuntime,
} from "./BaseWidget";
import type { UnregisteredCustomWidgetProps } from "./UnregisteredCustomWidget";
import type { PropertyPaneConfig } from "legacy/constants/PropertyControlConstants";
import type { ReduxAction } from "legacy/constants/ReduxActionConstants";
import type {
  CanvasWidgetsReduxState,
  FlattenedWidgetProps,
} from "legacy/reducers/entityReducers/canvasWidgetsReducer";
import type { DynamicWidgetsVisibilityState } from "legacy/selectors/visibilitySelectors";
import type {
  WidgetLayoutProps,
  FlattenedWidgetLayoutProps,
  FlattenedWidgetLayoutMap,
} from "legacy/widgets/shared";

export { CanvasWidgetsReduxState };
export type DerivedPropertiesMap = Record<string, string>;
export type WidgetActionResponse<WidgetType = FlattenedWidgetProps> =
  | Array<{
      widgetId: string;
      widget: WidgetType;
    }>
  | undefined
  | void;

type WidgetActionHookParams = {
  widgetId: string;
  widgets: Readonly<CanvasWidgetsReduxState>;
  action: ReduxAction<any>;
  originalWidgetValues: Record<string, any>;
  flags: Flags;
};
export type WidgetActionHook = (
  params: WidgetActionHookParams,
) => Generator<any, WidgetActionResponse, unknown> | WidgetActionResponse;
export type WidgetActionHookSync = (
  params: WidgetActionHookParams,
) => WidgetActionResponse;

// TODO (abhinav): To enforce the property pane config structure in this function
// Throw an error if the config is not of the desired format.

export type WidgetStaticFunctions = {
  [key in Exclude<
    keyof typeof BaseWidget,
    keyof typeof React.Component
  >]: (typeof BaseWidget)[key];
} & {
  allowedChildTypes?: WidgetType[];
  applyActionHook?: WidgetActionHook | WidgetActionHookSync;
  computeMinHeightFromProps?: (
    props: any,
    widgets: CanvasWidgetsReduxState | FlattenedWidgetLayoutMap,
    theme: GeneratedTheme,
    appMode: APP_MODE,
    dynamicVisibility: DynamicWidgetsVisibilityState,
  ) => Dimension<"px" | "gridUnit"> | undefined;
} & WidgetOperationsState;

interface WidgetOperationsState {
  enhancements?: {
    child: {
      autocomplete?: (
        parentProps: WidgetProps & {
          childAutocomplete: Record<string, unknown>;
        },
      ) => Record<string, unknown>;
    };
  };
}

class WidgetFactory {
  static widgetMap: Map<
    WidgetType,
    React.ComponentType<React.PropsWithChildren<WidgetLayoutProps>>
  > = new Map();
  static widgetPropValidationMap: Map<
    WidgetType,
    WidgetPropertyValidationType
  > = new Map();
  static widgetDerivedPropertiesGetterMap: Map<
    WidgetType,
    DerivedPropertiesMap
  > = new Map();
  static derivedPropertiesMap: Map<WidgetType, DerivedPropertiesMap> =
    new Map();
  static defaultPropertiesMap: Map<WidgetType, Record<string, string>> =
    new Map();
  static metaPropertiesMap: Map<WidgetType, Record<string, any>> = new Map();
  static propertyPaneConfigsMap: Map<
    WidgetType,
    readonly PropertyPaneConfig[]
  > = new Map();
  static widgetClasses: Map<WidgetType, WidgetStaticFunctions> = new Map();

  static registerWidgetBuilder(
    widgetType: WidgetType,
    widget: React.ComponentType<React.PropsWithChildren<any>>,
    widgetClass: React.ComponentClass<any> & WidgetStaticFunctions,
  ) {
    this.widgetMap.set(widgetType, widget);
    this.widgetPropValidationMap.set(
      widgetType,
      widgetClass.getPropertyValidationMap(),
    );
    this.derivedPropertiesMap.set(
      widgetType,
      widgetClass.getDerivedPropertiesMap(),
    );
    this.defaultPropertiesMap.set(
      widgetType,
      widgetClass.getDefaultPropertiesMap(),
    );
    this.metaPropertiesMap.set(widgetType, widgetClass.getMetaPropertiesMap());
    this.widgetClasses.set(widgetType, widgetClass);

    const staticallyReorganizedConfig =
      "getNewPropertyPaneConfig" in widgetClass &&
      widgetClass.getNewPropertyPaneConfig();
    if (staticallyReorganizedConfig) {
      registerV2PropertyPaneConfig(widgetType, staticallyReorganizedConfig);
    } else {
      registerPropertyPaneConfig(
        widgetType,
        widgetClass.getPropertyPaneConfig(),
      );
    }
  }

  static registerCustomComponentWidgetBuilder(
    widgetType: WidgetType,
    widget: React.ComponentType<React.PropsWithChildren<any>>,
    widgetClass: React.ComponentClass<any> & WidgetStaticFunctions,
    propertyValidationMap: WidgetPropertyValidationType,
    derivedPropertiesMap: DerivedPropertiesMap,
    defaultPropertiesMap: Record<string, string>,
    metaPropertiesMap: Record<string, any>,
    propertyPaneConfig: PropertyPaneConfig[],
  ) {
    this.widgetMap.set(widgetType, widget);
    this.widgetPropValidationMap.set(widgetType, propertyValidationMap);
    this.derivedPropertiesMap.set(widgetType, derivedPropertiesMap);
    this.defaultPropertiesMap.set(widgetType, defaultPropertiesMap);
    this.metaPropertiesMap.set(widgetType, metaPropertiesMap);
    this.widgetClasses.set(widgetType, widgetClass);

    registerPropertyPaneConfig(widgetType, propertyPaneConfig);
  }

  static createWidget(
    widgetData: WidgetLayoutProps & Partial<WidgetPropsRuntime>,
    appMode: APP_MODE,
  ): React.ReactNode {
    const widgetProps = {
      key: widgetData.widgetId,
      isVisible: true,
      ...widgetData,
      appMode: appMode,
    };

    const widgetComponent = this.widgetMap.get(widgetData.type);
    if (widgetComponent) {
      return React.createElement(widgetComponent, widgetProps);
    }
    if (isCustomComponentType(widgetData.type)) {
      const component = this.widgetMap.get(
        WidgetTypes.UNREGISTERED_CUSTOM_WIDGET,
      );
      if (component) {
        return React.createElement(component, {
          ...widgetProps,
          type: WidgetTypes.UNREGISTERED_CUSTOM_WIDGET,
          originalType: widgetData.type,
        } as UnregisteredCustomWidgetProps);
      }
    }
    const ex: WidgetCreationException = {
      message:
        "Widget Builder not registered for widget type" + widgetData.type,
    };
    console.error(ex);
    return null;
  }

  static getWidgetTypes(): WidgetType[] {
    return Array.from(this.widgetMap.keys());
  }

  static getWidgetPropertyValidationMap(
    widgetType: WidgetType,
  ): WidgetPropertyValidationType {
    const map = this.widgetPropValidationMap.get(widgetType);
    if (!map) {
      console.error("Widget type validation is not defined");
      return BASE_WIDGET_VALIDATION;
    }
    return map;
  }

  static getWidgetDerivedPropertiesMap(
    widgetType: WidgetType,
  ): DerivedPropertiesMap {
    const map = this.derivedPropertiesMap.get(widgetType);
    if (!map) {
      console.error("Widget type validation is not defined");
      return {};
    }
    return map;
  }

  static getWidgetDefaultPropertiesMap(
    widgetType: WidgetType,
  ): Record<string, string> {
    const map = this.defaultPropertiesMap.get(widgetType);
    if (!map) {
      console.error("Widget default properties not defined", widgetType);
      return {};
    }
    return map;
  }

  static getWidgetMetaPropertiesMap(
    widgetType: WidgetType,
  ): Record<string, any> {
    const map = this.metaPropertiesMap.get(widgetType);
    if (!map) {
      console.error("Widget meta properties not defined: ", widgetType);
      return {};
    }
    return map;
  }

  static getWidgetDocUrl(type?: WidgetType): string | undefined {
    return type ? WIDGET_DOC_URLS[type] : undefined;
  }

  static getWidgetTypeConfigMap(): WidgetTypeConfigMap {
    const typeConfigMap: WidgetTypeConfigMap = {};
    WidgetFactory.getWidgetTypes().forEach((type) => {
      typeConfigMap[type] = {
        validations: WidgetFactory.getWidgetPropertyValidationMap(type),
        defaultProperties: WidgetFactory.getWidgetDefaultPropertiesMap(type),
        derivedProperties: WidgetFactory.getWidgetDerivedPropertiesMap(type),
      };
    });
    return typeConfigMap;
  }

  static getWidgetParentChildSetup(): Partial<
    Record<
      WidgetType,
      {
        allowedChildTypes: string[];
      }
    >
  > {
    const typeConfigMap: Partial<
      Record<WidgetType, { allowedChildTypes: WidgetType[] }>
    > = {};
    WidgetFactory.getWidgetTypes().forEach((type) => {
      const widget = WidgetFactory.widgetClasses.get(type);
      if (widget?.allowedChildTypes) {
        typeConfigMap[type] = {
          allowedChildTypes: widget.allowedChildTypes,
        };
      }
    });
    return typeConfigMap;
  }

  static getWidgetSetup(): Partial<
    Record<
      WidgetType,
      {
        enhancements: WidgetStaticFunctions["enhancements"];
      }
    >
  > {
    const typeConfigMap: Partial<
      Record<
        WidgetType,
        {
          enhancements: WidgetStaticFunctions["enhancements"];
        }
      >
    > = {};
    WidgetFactory.getWidgetTypes().forEach((type) => {
      const widget = WidgetFactory.widgetClasses.get(type);
      typeConfigMap[type] = {
        enhancements: widget?.enhancements,
      };
    });
    return typeConfigMap;
  }

  static getWidgetClasses(): Record<string, WidgetStaticFunctions> {
    return Object.fromEntries(WidgetFactory.widgetClasses.entries());
  }

  // TODO add tests and refactor this function
  static getWidgetComputedHeight(
    props: WidgetProps | FlattenedWidgetLayoutProps,
    widgets: CanvasWidgetsReduxState | FlattenedWidgetLayoutMap,
    theme: GeneratedTheme,
    appMode: APP_MODE,
    dynamicVisibility: DynamicWidgetsVisibilityState,
  ): Dimension<"px"> | undefined {
    const widget = WidgetFactory.widgetClasses.get(props.type);

    if (props.height.mode === "fillParent") {
      // the computed height for a fillParent widget is its min-height because that is its basis
      // this is the value of its height that can affect its siblings/parent
      const widgetAHpx = Dimension.px(getWidgetAHFromProps(props));
      const maxHeightGridUnits = props.maxHeight
        ? Dimension.toPx(props.maxHeight, GridDefaults.DEFAULT_GRID_ROW_HEIGHT)
        : Dimension.px(Number.MAX_SAFE_INTEGER);
      return Dimension.min(widgetAHpx, maxHeightGridUnits).asFirst();
    }

    // min and max heights are dormant properties on fixed height widgets
    // however, don't short circuit here if we are a canvas widget since our height is controlled by parent
    if (
      widget &&
      isFixedHeight(props.height.mode) &&
      props.type !== WidgetTypes.CANVAS_WIDGET
    ) {
      return Dimension.toPx(props.height, GridDefaults.DEFAULT_GRID_ROW_HEIGHT);
    }

    if (widget && widget.computeMinHeightFromProps) {
      const parentWidget = widgets[props.parentId];

      const minHeightFromProps =
        Dimension.toPx(
          widget.computeMinHeightFromProps(
            props,
            widgets,
            theme,
            appMode,
            dynamicVisibility,
          ),
          GridDefaults.DEFAULT_GRID_ROW_HEIGHT,
        ) ?? Dimension.px(0);

      // our own minHeight and maxHeight is not valid if we are a column
      const widgetIsSectionColumn =
        props.type === WidgetTypes.CANVAS_WIDGET &&
        widgets[props.parentId]?.type === WidgetTypes.SECTION_WIDGET;

      // canvas widget height is not limited by parent max height
      const applicableMaxHeight = widgetIsSectionColumn
        ? undefined
        : getApplicableMaxHeight(props);

      const applicableMinHeight = widgetIsSectionColumn
        ? undefined
        : props.type === WidgetTypes.CANVAS_WIDGET
          ? getApplicableMinHeight(parentWidget)
          : getApplicableMinHeight(props);

      const contentHeight = applicableMaxHeight
        ? Dimension.min(
            minHeightFromProps,
            Dimension.toPx(
              applicableMaxHeight,
              GridDefaults.DEFAULT_GRID_ROW_HEIGHT,
            ),
          ).asFirst()
        : minHeightFromProps;

      const minHeightPx = applicableMinHeight
        ? Dimension.toPx(
            applicableMinHeight ?? Dimension.px(0),
            GridDefaults.DEFAULT_GRID_ROW_HEIGHT,
          )
        : undefined;

      return minHeightPx
        ? Dimension.max(contentHeight, minHeightPx).asFirst()
        : contentHeight;
    }
    console.warn("Container has no min height method", props.type);
    return undefined;
  }

  /** Minimum height is used for containers to allow them to be resized smaller, but not too small. Number is in gridUnits */

  static getWidgetMinimumHeight(
    props: WidgetProps,
    widgets: CanvasWidgetsReduxState | FlattenedWidgetLayoutMap,
    theme: GeneratedTheme,
    appMode: APP_MODE,
    dynamicVisibility: DynamicWidgetsVisibilityState,
  ): Dimension<"px"> | undefined {
    const widget = WidgetFactory.widgetClasses.get(props.type);
    if (widget && widget.computeMinHeightFromProps) {
      return Dimension.toPx(
        widget.computeMinHeightFromProps(
          props,
          widgets,
          theme,
          appMode,
          dynamicVisibility,
        ),
        GridDefaults.DEFAULT_GRID_ROW_HEIGHT,
      );
    }
    console.warn("Container has no min height method", props.type);
    return undefined;
  }
}

interface WidgetConfig {
  validations: WidgetPropertyValidationType;
  derivedProperties: DerivedPropertiesMap;
  defaultProperties: Record<string, string>;
}

export type WidgetTypeConfigMap = Record<string, WidgetConfig>;

interface WidgetCreationException {
  message: string;
}

export default WidgetFactory;
